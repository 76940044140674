const project = {
  ui: {
    contactcard: true,
    projecttitle: 'By Klaas Nienhuis',
    headerlogo: null,
    occludedannotationopacity: 0.0,
    movingannotationopacity: 0.2,
    expandactiveannotation: true,
    navigatebyannotationonly: false,    
    background: {
      image: '',
      color: '#5F2E2E',
      type: 'sketchfab'
    },
    theme: 'dark',
    checkout: true,
    pricing: false
  },
  scenes: [
    {
      scenetitle: 'Bicycle configurator',
      header: 'Prototype for Bayck',
      description: 'A new, versatile general examination table. The all-new 4040X general examination table is suitable for general examinations in different healthcare facilities.',
      sceneuid: 'd9f6610867d04219ba95cdf7d99b7031',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'data/lojer/assets/lojer4040x.jpg',
      menu: [

        {
          name: 'preset',
          title: 'Popular models',
          icon: 'mdi-palette',
          expand: true,
          options: [
            {presetgroup: 'bestsellers'},
          ]
        },

        {
          name: 'opt',
          title: 'Customize',
          icon: 'mdi-wrench',
          
          options: [
            {optionhook: 'speeds'},
            {optionhook: 'wheels'},
            {presetgroup: 'handles', init: false},
            {presetgroup: 'wheels', init: false},
            {visibilitygroup: 'drivetrain', hidden:true},
            {visibilitysingle: 'hierarchyhelpers', hidden: true},
            {visibilitysingle: 'chaincover'},
            {visibilitygroup: 'handle', hidden: true},
            {visibilitygroup: 'saddle'},
            {visibilitygroup: 'pedals'},
            {visibilitygroup: 'lc'},
            {visibilitygroup: 'fc'},
            {visibilitygroup: 'wheel', hidden: true},
            {visibilitygroup: 'fender', hidden: true},
            {presetgroup: 'fenders', init: false},

            {transform: 'handles', hidden: true},
            {visibilitygroup: 'cables', hidden: true},
            {transform: 'groundplane', hidden: true},
            {material: 'cover'},
          ]
        },
        {
          name: 'cam',
          title: 'Views',
          icon: 'mdi-camera',
          options: [
            {view: 'start'},
            {view: 'front'},
            {view: 'side'},
            {view: 'handle'},
            {view: 'luggage'},
            {view: 'saddle'},
          ]
        }
      ],
      player: {template: 'bayck', payload: {}},
      background: {template: 'bayck', payload: {}},
      environment: {template: 'bayck', payload: {}},
      postprocessing: {template: 'bayck', payload: {}},
      annotations: []      
    }
  ]
}

export default project;