const template = {
  optionhook: [
    {
      title: 'Drivetrain speeds',
      name: 'speeds',
      current: '7-speed',
      expand: true,
      options: [
        {
          name: '1-speed',
          title: 'One Speed'
        },
        {
          name: '7-speed',
          title: 'Seven Speed'
        }
      ]
    },
    {
      title: 'Wheels',
      name: 'wheels',
      current: 'wheel-m',
      options: [
        {
          name: 'wheel-m',
          title: 'Medium wheel'
        },
        {
          name: 'wheel-l',
          title: 'Large wheel'
        }
      ]
    }

  ],
  presetgroup: [
    {
      title: 'Handles',
      name: 'handles',
      type: 'group',
      visible: 'mes',
      expand: false,
      options: [
        {
          name: 'atb',
          title: 'Handle ATB 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'atb-1'},
            {visibilitygroup: 'cables', visible: 'atb-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'atb'}
          ]
        },
        {
          name: 'atb',
          title: 'Handle ATB 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'atb-7'},
            {visibilitygroup: 'cables', visible: 'atb-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'atb'}
          ]
        },
        {
          name: 'mes',
          title: 'Handle Messenger 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'mes-1'},
            {visibilitygroup: 'cables', visible: 'mes-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'mes'}
          ]
        },
        {
          name: 'mes',
          title: 'Handle Messenger 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'mes-7'},
            {visibilitygroup: 'cables', visible: 'mes-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'mes'}
          ]
        },
        {
          name: 'cru',
          title: 'Handle Cruiser 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'cru-1'},
            {visibilitygroup: 'cables', visible: 'cru-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'cru'}
          ]
        },
        {
          name: 'cru',
          title: 'Handle Cruiser 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'cru-7'},
            {visibilitygroup: 'cables', visible: 'cru-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'cru'}
          ]
        },
        {
          name: 'nos',
          title: 'Handle Nostalgic 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'nos-1'},
            {visibilitygroup: 'cables', visible: 'nos-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'nos'}
          ]
        },
        {
          name: 'nos',
          title: 'Handle Nostalgic 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'nos-7'},
            {visibilitygroup: 'cables', visible: 'nos-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'nos'}
          ]
        },
        {
          name: 'spo',
          title: 'Handle Sports 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'spo-1'},
            {visibilitygroup: 'cables', visible: 'spo-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'spo'}
          ]
        },
        {
          name: 'spo',
          title: 'Handle Sports 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'spo-7'},
            {visibilitygroup: 'cables', visible: 'spo-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'spo'}
          ]
        },
        {
          name: 'tow',
          title: 'Handle Town 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'tow-1'},
            {visibilitygroup: 'cables', visible: 'tow-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'tow'}
          ]
        },
        {
          name: 'tow',
          title: 'Handle Town 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'tow-7'},
            {visibilitygroup: 'cables', visible: 'tow-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'tow'}
          ]
        },
        {
          name: 'urb',
          title: 'Handle Urban 1 speed',
          optionhook: {name: 'speeds', option: '1-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'urb-1'},
            {visibilitygroup: 'cables', visible: 'urb-1'},
            {visibilitygroup: 'drivetrain', visible: 'speed-1'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'urb'}
          ]
        },
        {
          name: 'urb',
          title: 'Handle Urban 7 speed',
          optionhook: {name: 'speeds', option: '7-speed'},
          options: [
            {visibilitygroup: 'handle', visible: 'urb-7'},
            {visibilitygroup: 'cables', visible: 'urb-7'},
            {visibilitygroup: 'drivetrain', visible: 'speed-7'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'handles', default: 'urb'}
          ]
        },
      ]
    },
    {
      title: 'Wheels',
      name: 'wheels',
      type: 'group',
      visible: 'wheel',
      options: [
        {
          name: 'wheel',
          title: 'Medium wheels',
          optionhook: {name: 'wheels', option: 'wheel-m'},
          options: [
            {visibilitygroup: 'wheel', visible: 'medium'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'groundplane', default: 'medium'}
          ]
        },
        {
          name: 'wheel',
          title: 'Large wheels',
          optionhook: {name: 'wheels', option: 'wheel-l'},
          options: [
            {visibilitygroup: 'wheel', visible: 'large'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'groundplane', default: 'large'}
          ]
        },
      ]
    },
    {
      title: 'Bestsellers',
      name: 'bestsellers',
      type: 'group',
      visible: 'messenger',
      expand: true,
      options: [
        {
          name: 'messenger',
          title: 'Messenger',
          options: [
            {optionhook: 'speeds', current: '7-speed'},
            {optionhook: 'wheels', current: 'wheel-m'},
            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'mes'},
            {presetgroup: 'fenders', visible: 'none'},
            {visibilitygroup: 'lc', visible: 'none'},
            {visibilitygroup: 'fc', visible: 'none'},  
            
            {visibilitygroup: 'saddle', visible: 'com-g'},
            {visibilitygroup: 'pedals', visible: 'spo'},
            {visibilitysingle: 'chaincover', visible: false},
            {material: 'cover', visible:'Carbon'},

          ]
        },
        {
          name: 'cruiser',
          title: 'Cruiser',
          options: [
            {optionhook: 'wheels', current: 'wheel-l'},
            {optionhook: 'speeds', current: '1-speed'},
            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'cru'},
            
            {visibilitygroup: 'saddle', visible: 'cru'},
            {visibilitygroup: 'pedals', visible: 'cru'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'nol'},
            {visibilitygroup: 'lc', visible: 'none'},
            {visibilitygroup: 'fc', visible: 'none'},            
            {material: 'cover', visible:'Falls'},
          ]
        },
        {
          name: 'school',
          title: 'School',
          options: [
            {optionhook: 'wheels', current: 'wheel-m'},
            {optionhook: 'speeds', current: '7-speed'},

            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'nos'},
            {visibilitygroup: 'saddle', visible: 'com-g-white'},
            {visibilitygroup: 'pedals', visible: 'reg'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'lug'},
            {visibilitygroup: 'lc', visible: 'lcs'},
            {visibilitygroup: 'fc', visible: 'none'},
            {material: 'cover', visible:'Plum'},
          ]
        },
        {
          name: 'mule',
          title: 'Mule',
          options: [
            {optionhook: 'wheels', current: 'wheel-m'},
            {optionhook: 'speeds', current: '7-speed'},

            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'tow'},
            {visibilitygroup: 'saddle', visible: 'com-l-black'},
            {visibilitygroup: 'pedals', visible: 'rel'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'lug'},
            {visibilitygroup: 'lc', visible: 'lcu'},
            {visibilitygroup: 'fc', visible: 'acfcv'},
            {material: 'cover', visible:'Tomato'},

            
          ]
        }
      ]
    },
    {
      title: 'Fenders',
      name: 'fenders',
      type: 'group',
      visible: 'nol',
      options: [
        {
          name: 'none',
          title: 'No fender',
          options: [
            {visibilitygroup: 'fender', visible: 'none'}
          ]
        },
        {
          name: 'nol',
          title: 'Medium wheels, no luggage carrier',
          optionhook: {name: 'wheels', option: 'wheel-m'},
          options: [
            {visibilitygroup: 'fender', visible: 'm'}
          ]
        },
        {
          name: 'lug',
          title: 'Medium wheels, luggage carrier',
          optionhook: {name: 'wheels', option: 'wheel-m'},
          options: [
            {visibilitygroup: 'fender', visible: 'mlc'}
          ]
        },
        {
          name: 'nol',
          title: 'Large wheels, no luggage carrier',
          optionhook: {name: 'wheels', option: 'wheel-l'},
          options: [
            {visibilitygroup: 'fender', visible: 'l'}
          ]
        },
        {
          name: 'lug',
          title: 'Large wheels, luggage carrier',
          optionhook: {name: 'wheels', option: 'wheel-l'},
          options: [
            {visibilitygroup: 'fender', visible: 'llc'}
          ]
        }
      ]
    }
  ],  
  materialoptions: [
    {
      name: 'cover',
      title: 'Frame cover',
      type: 'single',
      visible: 'Titanium',
      scenematerial: ['Plastic-cover'],
      options: [
        {
          name: 'Sapphire',
          title: 'Sapphire',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [16, 76, 130] } },
          swatch: 'rgb(16,76,130)'
        },
        {
          name: 'Lagoon',
          title: 'Lagoon',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [29, 103, 128] } },
          swatch: 'rgb(29,103,128)'
        },
        {
          name: 'Titanium',
          title: 'Titanium',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [113, 111, 112] } },
          swatch: 'rgb(113,111,112)'
        },
        {
          name: 'Beach',
          title: 'Beach',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [203, 184, 154] } },
          swatch: 'rgb(203,184,154)'
        },
        {
          name: 'Carbon',
          title: 'Carbon',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [73, 71, 74] } },
          swatch: 'rgb(73,71,74)'
        },
        {
          name: 'Silver',
          title: 'Silver',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [190, 188, 189] } },
          swatch: 'rgb(190,188,189)'
        },
        {
          name: 'Pistachio',
          title: 'Pistachio',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [161, 158, 91] } },
          swatch: 'rgb(161,158,91)'
        },
        {
          name: 'Sunrise',
          title: 'Sunrise',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [229, 177, 76] } },
          swatch: 'rgb(229,177,76)'
        },
        {
          name: 'Onyx',
          title: 'Onyx',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [42, 40, 41] } },
          swatch: 'rgb(42,40,41)'
        },
        {
          name: 'Falls',
          title: 'Falls',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [40, 101, 145] } },
          swatch: 'rgb(40,101,145)'
        },
        {
          name: 'Pearl',
          title: 'Pearl',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [233, 233, 231] } },
          swatch: 'rgb(233,233,231)'
        },
        {
          name: 'Space',
          title: 'Space',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [106, 93, 84] } },
          swatch: 'rgb(106,93,84)'
        },
        {
          name: 'Tomato',
          title: 'Tomato',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [191, 25, 49] } },
          swatch: 'rgb(191,25,49)'
        },
        {
          name: 'Plum',
          title: 'Plum',
          basedon: 'skai_standard',
          payload: { AlbedoPBR: { color: [91, 56, 98] } },
          swatch: 'rgb(91,56,98)'
        }

      ]
    },
    {
      title: 'Handle',
      name: 'handle',
      type: 'single',
      scenematerial: ['Handle'],
      visible: 'Red',
      options: [
        {
          title: 'White',
          name: 'White',
          basedon: 'mattepaint',
          payload: { AlbedoPBR: { color: [255, 255, 255] } },
          swatch: 'rgb(255,255,255)'
        },
        {
          title: 'Red',
          name: 'Red',
          basedon: 'mattepaint',
          payload: { AlbedoPBR: { color: [168, 22, 22] } },
          swatch: 'rgb(168,22,22)'
        },
        {
          title: 'Yellow',
          name: 'Yellow',
          basedon: 'mattepaint',
          payload: { AlbedoPBR: { color: [255, 232, 0] } },
          swatch: 'rgb(255,232,0)'
        }
  
      ]
    },
    // {
    //   title: 'Global material',
    //   name: 'override',
    //   scenematerial: [],
    //   type: 'overrideexclude',
    //   options: [
    //     {
    //       title: White,
    //       basematerial: Paper,
    //       payload: {AlbedoPBR: {color: [200, 200, 200]}},
    //       swatch: rgb(250,250,250)
    //     },
    //     {option: black_painted_steel},
    //     {option: red_painted_steel},
    //     {option: yellow_painted_steel},
    //     {option: white_painted_steel}
    //   ]
    // },    
    {
      name: 'carpaint',
      title: 'Carpaint',
      type: 'colorway',
      options: [
        {
          name: 'carpaint_red',
          title: 'Red Rally',
          payload: [
            {
              basedon: 'carpaint_regular',
              scenematerial: ['Body_color_A', 'Body_color_B'],
              payload: {}
            },
            {
              basedon: 'carpaint_matte',
              scenematerial: ['Body_color_C'],
              payload: {}
            }
          ]
        }
      ]
    }    
  ],
  visibilitysingle: [
    {
      title: 'Hierarchy helpers',
      name: 'hierarchyhelpers',
      type: 'single',
      visible: false,
      objects: [
          'hierarchyhelper'
      ]
    },
    {
      title: 'Chaincover',
      name: 'chaincover',
      type: 'single',
      visible: true,
      objects: [
          'Chaincover'
      ]
    }
  ],
  visibilitygroup: [
    {
      title: 'Saddles',
      name: 'saddle',
      type: 'group',
      visible: 'vin',
      options: [
        {
          name: 'none',
          title: 'No saddle',
          objects: []
        },
        {
          name: 'com-g',
          title: 'Urban comfort',
          objects: ['Saddle-pen','Saddle-clamp-straight','SADDLE-COM-G']
        },
        {
          name: 'cru',
          title: 'Saddle Cruiser',
          objects: ['Saddle-pen','Saddle-clamp-straight','SADDLE-CRU']
        },
        {
          name: 'vin',
          title: 'Saddle Vintage',
          objects: ['Saddle-pen','Saddle-clamp-straight','Saddle-brooks']
        },
        {
          name: 'urb-g',
          title: 'Sabble Urban Gents Black',
          objects: ['Saddle-pen','Saddle-clamp-angle','SUGBL']
        },
        {
          name: 'com-l-white',
          title: 'Saddle Urban Comfort Ladies White',
          objects: ['Saddle-pen','Saddle-clamp-angle','SUCLWH']
        },
        {
          name: 'com-l-black',
          title: 'Saddle Urban Comfort Ladies Black',
          objects: ['Saddle-pen','Saddle-clamp-angle','SUCLBL']
        },
        {
          name: 'com-g-white',
          title: 'Saddle Urban Comfort Gents White',
          objects: ['Saddle-pen','Saddle-clamp-angle','SUCGWH']
        },
        {
          name: 'com-g-black',
          title: 'Saddle Urban Comfort Gents Black',
          objects: ['Saddle-pen','Saddle-clamp-angle','SUCGBL']
        },
      ]
    },    
    {
      title: 'Pedals',
      name: 'pedals',
      type: 'group',
      visible: 'cru',
      options: [
        {
          name: 'none',
          title: 'No pedals',
          objects: []
        },
        {
          name: 'cru',
          title: 'Pedals cruiser',
          objects: ['Pedals-cruiser']
        },
        {
          name: 'reg',
          title: 'Pedals regular',
          objects: ['Pedals-regular']
        },
        {
          name: 'rel',
          title: 'Pedals relax',
          objects: ['Pedals-relax']
        },
        {
          name: 'spo',
          title: 'Pedals sport',
          objects: ['Pedals-sport']
        },

      ]
    },    
    {
      title: 'Luggage carriers',
      name: 'lc',
      type: 'group',
      visible: 'none',
      options: [
        {
          name: 'none',
          title: 'No luggage carrier',
          objects: []
        },
        {
          name: 'lcs',
          title: 'Luggage carrier sports',
          objects: ['LC-base', 'LC-fender', 'LC-reflector']
        },
        {
          name: 'lcu',
          title: 'Luggage carrier utility',
          objects: ['LC-base', 'LC-fender', 'LC-reflector', 'LCU']
        }
      ]
    },    
    {
      title: 'Front carriers',
      name: 'fc',
      type: 'group',
      visible: 'none',
      options: [
        {
          name: 'none',
          title: 'No front carrier',
          objects: []
        },
        {
          name: 'acfcr',
          title: 'Front carrier regular',
          objects: ['ACF-base', 'ACF-wheel-tube-R-ST']
        },
        {
          name: 'acfcs',
          title: 'Front carrier sports',
          objects: ['ACF-base']
        },
        {
          name: 'acfcs1',
          title: 'Front carrier sports 2',
          objects: ['ACF-base', 'ACF-fork-bracket-S2-ST']
        },
        {
          name: 'acfcst',
          title: 'Front carrier sturdy',
          objects: ['ACF-base', 'ACFCST', 'ACF-fork-bracket-S2-ST', 'ACF-wheel-tube-R-ST']
        },
        {
          name: 'acfcv',
          title: 'Front carrier heavy duty',
          objects: ['ACF-base', 'ACFCST', 'ACF-fork-bracket-S2-ST', 'ACF-wheel-tube-R-ST', 'ACFCV']
        },
      ]
    },    
    {
      title: 'Wheels',
      name: 'wheel',
      type: 'group',
      visible: 'medium',
      options: [
        {
          name: 'none',
          title: 'No wheels',
          objects: []
        },
        {
          name: 'medium',
          title: 'Medium wheels',
          objects: ['Front-wheel-M', 'Front-wheel-lock', 'Rear-wheel-M', 'Disk-brake-front', 'Front-wheel-center'],
          matchNameExactly: true
        },
        {
          name: 'large',
          title: 'Large wheels',
          objects: ['Front-wheel-L', 'Front-wheel-lock', 'Rear-wheel-L', 'Disk-brake-front', 'Front-wheel-center'],
          matchNameExactly: true
        },
      ]
    },    
    {
      title: 'Drivetrain',
      name: 'drivetrain',
      type: 'group',
      visible: 'speed-1',
      options: [
        {
          name: 'none',
          title: 'No drivetrain',
          objects: []
        },
        {
          name: 'speed-1',
          title: 'One speed',
          objects: ['Drive-basic', 'Crank']
        },
        {
          name: 'speed-7',
          title: 'Seven speed',
          objects: ['Drive-basic', 'Drive-7-R', 'Crank']
        },
      ]
    },    
    {
      title: 'Fenders',
      name: 'fender',
      type: 'group',
      visible: 'none',
      options: [
        {
          name: 'none',
          title: 'No fender',
          objects: []
        },
        {
          name: 'm',
          title: 'Medium wheels, no luggage carrier',
          objects: ['Spat-basic-M', 'Spat-M-update', 'Spat-screws-F', 'Spat-screws-R']
        },
        {
          name: 'mlc',
          title: 'Medium wheels, luggage carrier',
          objects: ['Spat-basic-M', 'Spat-M-LC', 'Spat-screws-F', 'Spat-screws-R']
        },
        {
          name: 'l',
          title: 'Large wheels, no luggage carrier',
          objects: ['Spat-basic-L', 'Spat-L', 'Spat-screws-F', 'Spat-screws-R']
        },
        {
          name: 'llc',
          title: 'Large wheels, luggage carrier',
          objects: ['Spat-basic-L', 'Spat-L-LC', 'Spat-screws-F', 'Spat-screws-R']
        },
      ]
    },    
    {
      title: 'Handles',
      name: 'handle',
      type: 'group',
      visible: 'none',
      options: [
        {
          name: 'none',
          title: 'No handle',
          objects: []
        },
        {
          name: 'mes-1',
          title: 'Messenger 1-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-MES']
        },
        {
          name: 'mes-7',
          title: 'Messenger 7-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-MES']
        },
        {
          name: 'atb-1',
          title: 'ATB 1-speed',
          objects: ['stuurpen-atb','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-ATB'],
          matchNameExactly: true
        },
        {
          name: 'atb-7',
          title: 'ATB 7-speed',
          objects: ['stuurpen-atb','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-ATB'],
          matchNameExactly: true
        },
        {
          name: 'cru-1',
          title: 'Cruiser 1-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-CRU'],
          matchNameExactly: true
        },
        {
          name: 'cru-7',
          title: 'Cruiser 7-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-CRU'],
          matchNameExactly: true
        },
        {
          name: 'nos-1',
          title: 'Nostalgic 1-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-NOS']
        },
        {
          name: 'nos-7',
          title: 'Nostalgic 7-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-NOS']
        },
        {
          name: 'spo-1',
          title: 'Sports 1-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-SPO']
        },
        {
          name: 'spo-7',
          title: 'Sports 7-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-SPO']
        },
        {
          name: 'tow-1',
          title: 'Town 1-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-TOW']
        },
        {
          name: 'tow-7',
          title: 'Town 7-speed',
          objects: ['stuurpen40graden','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-TOW']
        },
        {
          name: 'urb-1',
          title: 'Urban 1-speed',
          objects: ['stuurpen-verstelbaar','Handle-brake-L','Handle-base-R','Grip-long-R','Handle-URB']
        },
        {
          name: 'urb-7',
          title: 'Urban 7-speed',
          objects: ['stuurpen-verstelbaar','Handle-brake-L','Handle-base-R','Handle-speed-brake-R','Handle-URB']
        },
      ]
    },
    {
      title: 'Cables',
      name: 'cables',
      type: 'group',
      visible: 'none',
      options: [
        {
          name: 'none',
          title: 'No wire',
          objects: []
        },
        {
          name: 'mes-1',
          title: 'Messenger 1-speed',
          objects: ["MES-wire-left"]
        },
        {
          name: 'mes-7',
          title: 'Messenger 7-speed',
          objects: ["MES-wire-left", "MES-wire-right"]
        },
        {
          name: 'atb-1',
          title: 'ATB 1-speed',
          objects: ["ATB-wire-left"],
          matchNameExactly: true
        },
        {
          name: 'atb-7',
          title: 'ATB 7-speed',
          objects: ["ATB-wire-left", "ATB-wire-right"],
          matchNameExactly: true
        },
        {
          name: 'cru-1',
          title: 'Cruiser 1-speed',
          objects: ["CRU-wire-left"],
          matchNameExactly: true
        },
        {
          name: 'cru-7',
          title: 'Cruiser 7-speed',
          objects: ["CRU-wire-left", "CRU-wire-right"],
          matchNameExactly: true
        },
        {
          name: 'nos-1',
          title: 'Nostalgic 1-speed',
          objects: ["NOS-wire-left"]
        },
        {
          name: 'nos-7',
          title: 'Nostalgic 7-speed',
          objects: ["NOS-wire-left", "NOS-wire-right"]
        },
        {
          name: 'spo-1',
          title: 'Sports 1-speed',
          objects: ["SPO-wire-left"]
        },
        {
          name: 'spo-7',
          title: 'Sports 7-speed',
          objects: ["SPO-wire-left", "SPO-wire-right"]
        },
        {
          name: 'tow-1',
          title: 'Town 1-speed',
          objects: ["TOW-wire-left"]
        },
        {
          name: 'tow-7',
          title: 'Town 7-speed',
          objects: ["TOW-wire-left", "TOW-wire-right"]
        },
        {
          name: 'urb-1',
          title: 'Urban 1-speed',
          objects: ["URB-wire-left"]
        },
        {
          name: 'urb-7',
          title: 'Urban 7-speed',
          objects: ["URB-wire-left", "URB-wire-right"]
        },
      ]
    },       
  ],
  transform: [
    {
      name: 'handles',
      title: 'Handles',
      type: 'matchmatrix',
      default: 'atb',
      options: [
        {
          name: 'mes',
          title: 'MES handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-MES-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-MES-handle-R-target'
              }
            ]
          },
          label: 'mes'
        },
        {
          name: 'atb',
          title: 'ATB handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-ATB-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-ATB-handle-R-target'
              }
            ]
          },
          label: 'atb'
        },
        {
          name: 'cru',
          title: 'CRU handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-CRU-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-CRU-handle-R-target'
              }
            ]
          },
          label: 'cru'
        },
        {
          name: 'nos',
          title: 'NOS handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-NOS-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-NOS-handle-R-target'
              }
            ]
          },
          label: 'nos'
        },
        {
          name: 'spo',
          title: 'SPO handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-SPO-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-SPO-handle-R-target'
              }
            ]
          },
          label: 'spo'
        },
        {
          name: 'tow',
          title: 'TOW handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-TOW-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-TOW-handle-R-target'
              }
            ]
          },
          label: 'tow'
        },
        {
          name: 'urb',
          title: 'URB handle',
          payload: {
            sets: [
              {
                subjects: ['Handle-brake-L'],
                target: 'Handle-URB-handle-L-target'
              },
              {
                subjects: ['Handle-speed-brake-R','Grip-long-R','Handle-base-R'],
                target: 'Handle-URB-handle-R-target'
              }
            ]
          },
          label: 'urb'
        },
      ]
    },
    {
      name: 'groundplane',
      title: 'Groundplane',
      type: 'matchmatrix',
      default: 'medium',
      options: [
        {
          name: 'medium',
          title: 'Medium',
          payload: {
            sets: [
              {
                subjects: ['Groundplane'],
                target: 'Groundplane-M-target',
                matchNameExactly: true
              },
 
            ]
          },
          label: 'M'
        },
        {
          name: 'large',
          title: 'Large',
          payload: {
            sets: [
              {
                subjects: ['Groundplane'],
                target: 'Groundplane-L-target',
                matchNameExactly: true
              },
 
            ]
          },
          label: 'L'
        }
      ]
    }    
  ],
  materials: [
    {
      name: 'carpaint_regular',
      library: 'Steel',
      payload: { 
        AlbedoPBR: {color: [200, 200, 200]},
        RoughnessPBR: {factor: 0.1}
      },
      swatch: 'rgb(230,230,230)'
    },
    {
      name: 'skai_standard',
      library: 'Skai',
      payload: { 
        AlbedoPBR: {color: [200, 200, 200]},
        RoughnessPBR: {factor: 0.1}
      },
      swatch: 'rgb(230,230,230)'
    },
    {
      name: 'mattepaint',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [255, 255, 255]},
        GlossinessPBR: {factor: 0.65},
        SpecularF0: {factor: 0.5}
      },
      swatch: 'rgb(255,255,255)'
    },   
  ],
  views: [
    {
      title: 'Start',
      name: 'start',
      payload: {
        eye: [164.16,94.00,31.08],
        lookat: [-4.57,28.60,25.53],
        fov: 55
      }
    },
    {
      title: 'Front',
      name: 'front',
      payload: {
        eye: [3.40,197.40,38.22],
        lookat: [1.05,17.68,16.35],
        fov: 55
      }
    },
    {
      title: 'Side',
      name: 'side',
      payload: {
        eye: [-175.01,24.11,15.53],
        lookat: [5.92,17.65,17.09],
        fov: 55
      }
    },
    {
      title: 'Handle bar',
      name: 'handle',
      payload: {
        eye: [-49.97,3.98,86.59],
        lookat: [-5.44,38.53,60.39],
        fov: 55
      }
    },
    {
      title: 'Luggage carrier',
      name: 'luggage',
      payload: {
        eye: [-64.12,-45.48,36.79],
        lookat: [-2.50,-48.67,27.07],
        fov: 55
      }
    },
    {
      title: 'Saddle',
      name: 'saddle',
      payload: {
        eye: [-20.11,-1.09,77.24],
        lookat: [1.77,-21.96,55.91],
        fov: 55
      }
    }
  ],
  player: [
    {
      name: 'bayck',
      library: 'avoidunder',
      payload: {
        orbit_constraint_zoom_in: 30,
        orbit_constraint_zoom_out: 500
      }
    }
  ],
  background: [
    {
      name: 'bayck',
      library: 'white',
      payload: {
      }
    }
  ],
  environment: [
    {
      name: 'bayck',
      library: 'bayck',
      payload: {
      }
    }
  ],
  postprocessing: [
    {
      name: 'bayck',
      library: 'everything_off',
      payload: {
        ssrEnable: true,
        ssrFactor: 1,
        ssaoEnable: true,
        ssaoRadius: 7.9660302282,
        ssaoIntensity: 0.3490701001,
        ssaoBias: 1.4094732978,
        sharpenEnable: true,
        sharpenFactor: 0.0722061559,
        vignetteEnable: true,
        vignetteAmount: 0.2117310443490701,
        vignetteHardness: 0.6237482117,
        bloomEnable: true,
        bloomFactor: 0.10300429184549356,
        bloomThreshold: 0.2984790690256377,
        bloomRadius: 0.17739628040057226,
        toneMappingEnable: true,
        toneMappingMethod: "filmic",
        toneMappingExposure: 1.9341917024,
        toneMappingBrightness: 0.2360515021,
        toneMappingContrast: -0.0271816881,
        toneMappingSaturation: 1,               
      }
    }
  ],
  annotations: [
    
  ],
  labels: [
    {
      name: 'wheel',
      title: 'Wheels',
      payload: {
        text: 'The wheels are made of a strong and chemical resistant compound. The wheels leave no trace on all floor types.\nClean with all purpose cleaner.'
      }
    }
  ]
}
  
export default template;