const library = {
  materials: [
    {
      name: 'Steel',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [170, 170, 170]},
        MetalnessPBR:{factor: 1},
        RoughnessPBR: {enable: true, factor: 0.15},
        GlossinessPBR: {enable: false},
        ClearCoat: {enable: false},
        SpecularF0: {factor: 0.0}
      }
    },
    {
      name: 'Skai',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [128, 128, 128]},
        GlossinessPBR: {factor: 0.7},
        SpecularF0: {factor: 0.3}
      }
    }    
  ],
  player: [
    {
      name: 'avoidunder',
      payload: {
        orbit_constraint_pitch_down: -0.1,
        orbit_constraint_pitch_up: 1.5,
        orbit_constraint_pan: 0
      }
    }
  ],
  postprocessing: [
    {
      name: 'everything_off',
      payload: {
        enable: true,
        AR: false,
        bloomEnable: false,
        chromaticAberrationEnable: false,
        colorBalanceEnable: false,
        doDistortionVR: false,
        dofEnable: false,
        grainEnable: false,
        sharpenEnable: false,
        ssaoEnable: false,
        ssrEnable: false,
        taaEnable: true,
        taaTransparent: true,
        toneMappingEnable: false,
        vignetteEnable: false,
        vrFading: false,
        vrStartFade: false,
        webVR: false    
      }
    }
  ],
  environment: [
    {
      name: 'bayck',
      payload: {
        enabled: true,
        exposure: 0.6,
        lightIntensity: 1.4,
        rotation: 2.8439038663,
        shadowEnabled: true,
        uid: 'd106177604ef44cf8cdc539e4d942423'
      }
    },
  ],
  background: [
    {
      name: 'white',
      payload: {
        enable: 'color',
        color: [1, 1, 1]
      }
    },
    {
      name: 'lightgrey',
      payload: {
        enable: 'color',
        color: [0.9, 0.9, 0.9]        
      }
    }
  ]
}
  
export default library;